@import '../node_modules/antd/dist/antd.css';
@import './styles/tailwindmap.css';
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body{
  overflow-x: hidden;
}

.box {
  border: 1px solid #ebedf0;
  border-radius: 2px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 16px;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.dashboard .ant-btn {
  margin-bottom: 8px;
}

.emphasis {
  font-weight: bold;
}

.logo {
  height: 40px;
  color: #fff;
  font-size: 24px;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.ant-btn-primary{
  background-color: #1890ff;
}
.ant-input-number{
  width: 100%;
  border-radius: 5px;
}
.content--layout{
  padding: 16px;
  position: relative;
  min-height: 360px;
}
.ant-drawer-close{
  color: #fff !important;
}
@media screen and (max-width: 600px) {
  div, p, span, a{
    font-size: 13px !important;
  }
  .ant-table-cell, .ant-card-body{
    padding: 2px !important;
  }
  .content--layout{
    padding: 5px !important;
  }
}

.font-medium {
  font-weight: bold;
}

.small-avatar {
  max-width: 32px;
  display: inline-block;
  margin: 2px;
}